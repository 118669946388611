import React from 'react'
import StyledCrossMenu from './StyledCrossMenu'

export const CrossMenu = () => {


    return (
        <StyledCrossMenu>
            <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M3 0L31.2843 28.2843L28.4558 31.1127L0.171573 2.82843L3 0Z" fill="#82211D"/>
                <path d="M0 28.2843L28.2843 2.6226e-05L31.1127 2.82845L2.82843 31.1127L0 28.2843Z" fill="#82211D"/>
            </svg>
        </StyledCrossMenu>
    );
};