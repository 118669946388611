import React from 'react'
import StyledTrustUs from './StyledTrustUs'
import Orange from '../../Images/Orange_logo.svg'
import Inter from '../../Images/Logo_Intermarché.svg'
import Indra from '../../Images/Indra-Sistemas.svg'
import Mag from '../../Images/logo_magellanconsulting.svg'
import Tech from '../../Images/TechnipFMC.svg'
import Sopra from '../../Images/Sopra_Steria.svg'
import Micro from '../../Images/Micro.svg'
import Acensi from '../../Images/Acensi.svg'



export const TrustUs = ({client}) => {

    const trustArray = [
        {
            img: Orange
        },
        {
            img: Inter
        },
        {
            img: Indra 
        },
    ]

    const trustArrayBis = [
        {
            img: Mag
        },
        {
            img: Tech
        },
        {
            img: Sopra 
        },
    ]

    const trustArrayLast = [
        {
            img: Micro
        },
        {
            img: Acensi
        },
    ]


    return (
        <StyledTrustUs ref={client}>
            <h1>il nous font confiance</h1>
            <div>
                {
                    trustArray.map((trustArray) => {
                        return(
                            <img src={trustArray.img} alt='corp trust' />
                        )
                    })
                }
            </div>
            <div>
                {
                    trustArrayBis.map((trustArrayBis) => {
                        return(
                            <img src={trustArrayBis.img} alt='corp trust' />
                        )
                    })
                }
            </div>
            <div>
                {
                    trustArrayLast.map((trustArrayLast) => {
                        return(
                            <img src={trustArrayLast.img} alt='corp trust' />
                        )
                    })
                }
            </div>
        </StyledTrustUs>
    )
}
