import styled,{css, keyframes} from 'styled-components'
import { colors } from '../../Theme/Helpers'

const StyledHeader = styled.header`

    @keyframes OpenNav{
        0%{
            right: -100%;
        }
        100%{
            right: 0px;
        }
    }

    @keyframes CloseNav{
        0%{
            right: 0px;
        }
        100%{
            right: -100%;
        }
    }

    @keyframes NavReduction {
            0% {
                height:unset;
            }
            100%{
                height:48px;
            }
        }

    @keyframes NavAugmentation {
        0% {
            height:48px;
        }
        100%{
            height:unset;
        }
    }

    @keyframes LogoPaddingAugmentation {
        0% {
            padding: 12px 48px 12px 48px;
        }
        100%{
            padding: 36px 48px 36px 48px;
        }
    }

    @keyframes LogoPaddingReduction {
        0% {
            padding: 36px 48px 36px 48px;
        }
        100%{
            padding: 12px 48px 12px 48px;
        }
    }

    @keyframes NavMarginAugmentation {
        0% {
            margin-top: 4px;
        }
        100%{
            margin-top: 12px;
        }
    }

    @keyframes NavMarginReduction {
        0% {
            margin-top: 12px;
        }
        100%{
            margin-top: 4px;
        }
    }
    
    @keyframes ImgMarginAugmentation {
        0% {
            margin-top: 3px;
        }
        100%{
            margin-top: 6px;
        }
    }

    @keyframes ImgMarginReduction {
        0% {
            margin-top: 6px;
        }
        100%{
            margin-top: 3px;
        }
    }

    display: flex;
    z-index: 10;
    position: fixed;
    width: 100%;
    ${({isTop}) => isTop && css`
        animation: NavAugmentation cubic-bezier(0.71, -0.01, 0.4, 0.97) 300ms forwards;
    `}
    ${({isTop}) => isTop === false && css`
        animation: NavReduction cubic-bezier(0.71, -0.01, 0.4, 0.97) 300ms forwards;
    `}

    .burger-menu{
        display: none;
    }


    .div-logo {
        ${({isTop}) => isTop && css`
        animation: NavAugmentation cubic-bezier(0.71, -0.01, 0.4, 0.97) 300ms forwards;
        `}
        ${({isTop}) => isTop === false && css`
            animation: NavReduction cubic-bezier(0.71, -0.01, 0.4, 0.97) 300ms forwards;
        `}
        display:flex;
        align-items: center;
        justify-content: center;
        background-color: ${colors.primary};
        ${({isTop}) => isTop && css`
        animation: LogoPaddingAugmentation cubic-bezier(0.71, -0.01, 0.4, 0.97) 300ms forwards;
        `}
        ${({isTop}) => isTop === false && css`
            animation: LogoPaddingReduction cubic-bezier(0.71, -0.01, 0.4, 0.97) 300ms forwards;
        `}
        cursor: pointer;
    }

    .div-menu{
        width: 100%;

        ul{
            width: 100%;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            background: ${({isTop}) => isTop ? 'rgba(1, 29, 54, 0.6)' : 'rgba(1, 29, 54, 0.4)'};
            backdrop-filter: blur(10px);

            li{
                height: 100%;
                margin: 0px 48px;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                cursor: pointer;
            }

            span{
                ${({isTop}) => isTop && css`
                    animation: NavMarginReduction cubic-bezier(0.71, -0.01, 0.4, 0.97) 300ms forwards;
                `}
                ${({isTop}) => isTop === false && css`
                    animation: NavMarginAugmentation cubic-bezier(0.71, -0.01, 0.4, 0.97) 300ms forwards;
                `}
                font-family: Josefin Sans;
                font-style: normal;
                font-weight: 600;
                font-size: 18px;
                line-height: 18px;
                display: flex;
                align-items: center;
                text-align: center;
                color: ${colors.white};
                cursor: pointer
            }

            .pick{
                width: 100%;
                margin-top: ${({isTop}) => isTop ? '6px' : '3px'};
                ${({isTop}) => isTop && css`
                    animation: ImgMarginAugmentation cubic-bezier(0.71, -0.01, 0.4, 0.97) 300ms forwards;
                `}
                ${({isTop}) => isTop === false && css`
                    animation: ImgMarginReduction cubic-bezier(0.71, -0.01, 0.4, 0.97) 300ms forwards;
                `}
                opacity: 0;
                transform: translate(-150px);
                transition: 300ms;
            }


            li:hover{

                .pick{
                    margin-top: ${({isTop}) => isTop ? '6px' : '3px'};
                    opacity: 1;
                    transform: translate(0px);
                }
            }

            .image-logo{
                display: none;
            }
        }
    }

    @media screen and (max-width: 1300px){
        .div-menu{
            ul{
                justify-content: space-around;
                li{
                    margin: 0px 0px;
                }
                span{
                    font-size: 16px;
                }
            }
        }
    }
    
    @media screen and (max-width: 980px){
        animation: unset!important;
        height: 48px;
        background-color: ${colors.primary};

        .div-logo{
            animation: unset!important;
            margin-left: 24px;
        }

        .div-menu{
            position: absolute;
            top: 0px; 
            ${({navIsOpen}) => navIsOpen === null && css`
            right: -100%;
            `}
            ${({navIsOpen}) => navIsOpen && css`
                animation: OpenNav cubic-bezier(0.71, -0.01, 0.4, 0.97) 500ms forwards;
            `}
            ${({navIsOpen}) => navIsOpen === false && css`
                animation: CloseNav cubic-bezier(0.71, -0.01, 0.4, 0.97) 500ms forwards;
            `}
            display: flex;
            justify-content: flex-end;
            height: 100vh;
            backdrop-filter: blur(10px);

            ul{
                height: 600px;
                width: 90%;
                background-color: ${colors.primary};
                display: flex!important;
                flex-direction:column!important;
                align-items: center!important;
                justify-content: flex-start;
                padding-top: 60px;

                li{
                    height: 100px;
                    margin: 0px 48px;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;
                    cursor: pointer;
                }
            }

            span {
                animation: unset!important;
            }

            .pick {
                animation: unset!important;
                margin: 3px!important;
            }

            .image-logo{
                display: flex!important;
                width: 200px;
            }
        }

        .burger-menu{
            position: absolute;
            right: 24px;
            top: 12px;
            background-color: ${colors.primary};
            display: unset;
            cursor: pointer;
        }


    }
`
export default StyledHeader