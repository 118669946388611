import styled from 'styled-components'
import { colors } from '../../Theme/Helpers'

const StyledWhoWeAre = styled.section`
    .title-who{
        font-family: Josefin Sans;
        font-style: normal;
        font-weight: bold;
        font-size: 30px;
        line-height: 30px;
        text-align: center;
        text-transform: uppercase;
        padding-top: 24px;
        margin-bottom: 24px;

        color: ${colors.primary};
    }

    .pres{
        display: flex;
        align-items: center;
        width: 100%;

        .founder-corp{
            position: relative;
            width: 50%;
            overflow: hidden;

            img{
                width: 100%;
                transform: scale(1.01);
                height: auto;
                z-index: -1;
                transition: 300ms;
            }

            .denomination{
                z-index: 1;
                position: absolute;
                top: 10%;
                left: 10%;

                h3{
                    font-family: Josefin Sans;
                    font-style: normal;
                    font-weight: normal;
                    font-size: 18px;
                    line-height: 18px;
                    display: flex;
                    align-items: center;
                    text-transform: uppercase;

                    color: ${colors.white};
                }

                span{
                    font-family: Josefin Sans;
                    font-style: normal;
                    font-weight: bold;
                    font-size: 18px;
                    line-height: 18px;
                    display: flex;
                    align-items: center;
                    text-transform: uppercase;

                    color: ${colors.white};
                }
            }

            .description{
                display: flex;
                align-items: center;
                justify-content: flex-end;
                position: absolute;
                bottom: 0px;
                z-index: 1;
                background: rgba(255, 255, 255, 0.2);
                backdrop-filter: blur(10px);
                padding: 6px 0px 6px 0px;

                p{
                    width: 40%;
                    font-family: Josefin Sans;
                    font-style: normal;
                    font-weight: normal;
                    font-size: 12px;
                    line-height: 16px;
                    margin-right: 24px;

                    color: ${colors.primary};
                }
                div {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    padding: 36px 60px;
                }
            }
        }
        .hover-button{
            display: flex;
            align-items: center;
            justify-content: center;
            background: ${colors.primary};
            border-radius: 4px;
            color: ${colors.white};
            font-family: Josefin Sans;
            font-style: normal;
            font-weight: bold;
            font-size: 14px;
            text-transform: uppercase;
            padding: 18px 48px;
            cursor: pointer;
            transition: 500ms;
        }

        .founder-corp:hover{
            img{
                transform: scale(1.06);
                z-index: -1;
            }
        }

        .hover-button:hover{
            background: ${colors.segondary};
        }
    }

    @media screen and (max-width: 1150px){

        .pres{
            display: flex;
            flex-direction: column;

            .founder-corp{
                width: 70%;

                .description{
                    background: rgba(255, 255, 255, 0.2);
                    backdrop-filter: blur(5px);
                    padding: 24px 0px;

                    div {
                        background: unset;
                        backdrop-filter: unset;
                    }
                }
            }
        }
    }

    @media screen and (max-width: 840px){
        .founder-corp{
            width: 100%!important;
        }
    }

    @media screen and (max-width: 560px){
        .description{
            flex-direction: column;
            padding: 24px 0px 0px !important;
            justify-content: center!important;

            p{
                width: 90%!important;
                text-align: center;
            }
            div{
                padding: 12px 0px!important;
            }
        }

        .founder-corp{

            img{
                transform: scale(1.02)!important;
            }
            img:hover{
                transform: scale(1.07)!important;
            }
        }
    }

`

export default StyledWhoWeAre