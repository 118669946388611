import React, {useState} from 'react'
import StyledFooter from './StyledFooter'
import emailjs from 'emailjs-com';
import { init } from 'emailjs-com';
init("user_cIqoksdtb37yG7X3krpba");

export const Footer = ({ footer }) => {

    const [title, setTitle] = useState('')

    function sendEmail(e) {
        e.preventDefault();
        if (title === '') {
            emailjs.sendForm('service_c920vks','template_r3zyyul', e.target, 'user_gRMO3y0Jx6qG6ZaktjMN2')
            .then((result) => {
                console.log(result.text);
            }, (error) => {
                console.log(error.text);
            });
        }
        e.target.reset()
    }


    return (
        <StyledFooter ref={footer}>
            <div className='separator'>
                <div className='thin-bar'></div>
                <div className='bold-bar'></div>
            </div>
            <div className='footer-contact'>
                <div className='info'>
                    <h1 className='title-call'>contact</h1>
                    <div className='container-email-tel'>
                        <div className='email'>
                            <h3 className='contact-value'>Email :</h3>
                            <a className='text-contact' href='mailto:christophe.neret@xlance.fr'>christophe.neret@xlance.fr</a>
                            <a className='text-contact' href='mailto:eric.agniel@xlance.fr'>eric.agniel@xlance.fr</a>
                        </div>
                        <div className='tel'>
                            <h3 className='contact-value'>Téléphone :</h3>
                            <span className='text-contact'>
                                +33 6 82 65 18 97
                            </span>
                            <span className='text-contact'>
                                +33 6 09 92 91 00
                            </span>
                        </div>
                    </div>
                    <div className='address'>
                        <h3 className='contact-value'>Adresse :</h3>
                        <p className='text-contact address'>
                            35 Rue Dunois, 75013 Paris France
                        </p>
                    </div>
                    <div className='social-part'>
                        <a className='text-contact social' href='#'>Linkekdin</a>
                        <a className='text-contact' href='#'>Email</a>
                    </div>
                </div>
                <div className='container-form'>
                    <h1 className='title-form'>Formulaire de contact</h1>
                    <form onSubmit={sendEmail}>
                        <div>   
                                <input className='input_select input-formulary'  type="text" name='from_name' placeholder='Nom' required /> 
                                <input className='input_select input-formulary' type="text" name='from_corporate' placeholder='Entreprise' required/>
                        </div>
                        <div>
                            <input className='input_select input-formulary' type="e-mail" name='from_email' placeholder='Email' required/>
                            <input className='input_select input-formulary' type="tel" name='from_tel' placeholder='Tel' required/>
                            <input className='input_select input-formulary' value={title} type="hidden" onChange={event => setTitle(event.target.value)}/>
                        </div>
                        <div>      
                            <textarea className='input_select' name='message' placeholder='Message' required></textarea>
                        </div>
                        <input className='input_select submit-button' type="submit" value="Envoyer"></input>
                    </form>
                </div>
            </div>
        </StyledFooter>
    )
}
