import React from 'react'
import StyledPresentation from './StyledPresentation'
import styled from 'styled-components'


const VectorDecoOneBlue = () => {
    const StyledVectorDecoOneBlue = styled.div`
    `;

    return (
        <StyledVectorDecoOneBlue>
            <svg width="187" height="64" viewBox="0 0 187 64" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M112.906 0H187L74.0943 64H0L112.906 0Z" fill="#011D36"/>
            </svg>
        </StyledVectorDecoOneBlue>
    );
};

const VectorDecoTwoBlue = () => {
    const StyledVectorDecoTwoBlue = styled.div`
    margin-left: -30px;
    `;

    return (
        <StyledVectorDecoTwoBlue>
            <svg width="176" height="64" viewBox="0 0 176 64" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M115.556 0H176L60.4444 64H0L115.556 0Z" fill="#011D36"/>
            </svg>
        </StyledVectorDecoTwoBlue>
    );
};

const VectorDecoTriangle = () => {
    const StyledVectorDecoTriangle = styled.div`
    position: absolute;
    bottom: 150px;
    right: 96px;

    @media screen and (max-width: 840px){
        display: none;
    }
    `;

    return (
        <StyledVectorDecoTriangle>
            <svg width="144" height="192" viewBox="0 0 144 192" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M144 192L0 192L144 0L144 192Z" fill="#82211D" fill-opacity="0.05"/>
            </svg>
        </StyledVectorDecoTriangle>
    );
};




export const Presentation = ({presentation}) => {
    return (
        <StyledPresentation ref={presentation}>
            <div className='vector-position'>
                <VectorDecoOneBlue/>
                <VectorDecoTwoBlue/>
            </div>
            <div className='container-content'>
                <div className='road-corp'>
                    <div className='chrono-info'>
                        <span className='denomination'>
                            Création
                        </span>
                        <span className='date'>
                            2017
                        </span>
                    </div>
                    <div className='chrono-bar'>
                        <div className='thin-bar'></div>
                        <div className='bold-bar'></div>
                    </div>
                    <div className='chrono-info'>
                        <span className='denomination'>
                            Site web
                        </span>
                        <span className='date'>
                            2020
                        </span>
                    </div>
                </div>
                <div className='div-pres'>
                    <h1 className='title'>
                        Présentation
                    </h1>
                    <p className='para'>
                        XLance, une entreprise dédiée à la mise à disposition de compétences IT et consulting (Freelance et CDI) dans le monde entier. Nous cherchons à
                        amener l’innovation auprès de nos clients sur ce métier qui nous passionne. 
                    </p>
                </div>
                <div className='div-ambition'>
                    <h1 className='title'>
                        Nos Ambitions
                    </h1>
                    <p className='para'>
                        Accompagner les entreprises de service numérique (ESN),
                        société de conseil et de stratégie dans la recherche des
                        meilleurs profils pour leur clients.
                        Devenir un acteur incontournable du recrutement au travers
                        d’une plateforme digitale
                        .
                        Fournir des profils sur les principaux secteurs d’activité
                    </p>
                </div>
            </div>
            <VectorDecoTriangle/>
        </StyledPresentation>
    )
}
