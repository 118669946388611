const { Landing } = require("./Pages/Landing");
const { default: Global } = require("./Theme/GlobalStyle");

function App() {
  return (
    <div className="App">
      <Global/>
      <Landing/>
    </div>
  );
}

export default App;
