import React from 'react'
import StyledHome from './StyledHome'
import styled from 'styled-components'

import Logo from '../../Images/LogoXlance.svg'
import img from '../../Images/PhotoHome.jpg'
import vectorScroll from '../../Images/VectorScroll.svg'

const VectorDecoOneWhite = () => {
    const StyledVectorDecoOneWhite = styled.div`
    `;

    return (
        <StyledVectorDecoOneWhite>
            <svg width="187" height="16" viewBox="0 0 187 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M112.906 0H187L74.0943 64H0L112.906 0Z" fill="#E5E5E5"/>
            </svg>
        </StyledVectorDecoOneWhite>
    );
};

const VectorDecoTwoWhite = () => {
    const StyledVectorDecoTwoWhite = styled.div`
    margin-left: -30px;
    `;

    return (
        <StyledVectorDecoTwoWhite>
        <svg width="176" height="16" viewBox="0 0 176 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M115.556 0H176L60.4444 64H0L115.556 0Z" fill="#E5E5E5"/>
        </svg>
        </StyledVectorDecoTwoWhite>
    );
};

export const Home = ({ coordonate }) => {
    return (
        <StyledHome>
            <img style={{transform: `translate(${coordonate.x}px, ${coordonate.y}px)`}} className='background' src={img} alt="background image"/>
            <div className='title-home'>
                <img src={Logo} alt="Logo Xlance"/>
                <div className='position-denomination'>
                    <span>Consulting IT</span><span className='separator'>|</span><span> Placement </span> <span className='separator'>|</span><span> Recrutement</span>
                </div>

            </div>
            <div className='vector-decoration'>
                <VectorDecoOneWhite/>
                <VectorDecoTwoWhite/>
            </div>
            <div className='scroll'>
                <p>Scrollez vers le bas</p>
                <img src={vectorScroll} alt="vector scroll"/>
            </div>
        </StyledHome>
    )
}
