import styled from 'styled-components'
import { colors } from '../../Theme/Helpers'

const StyledPresentation = styled.section `
    background-color: white;
    position: relative;

    .vector-position{
        display: flex;
        position: absolute;
        top: -16px;
        left: 125px;
        z-index: 0;
    }

    .container-content{
        padding: 144px 13px;
        display: flex;
        align-items: start;
        justify-content: space-between;

        .road-corp{
            width: 20%;
            .chrono-info{
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
            }
            .denomination{
                font-family: Josefin Sans;
                font-style: normal;
                font-weight: 300;
                font-size: 12px;
                line-height: 12px;
                display: flex;
                align-items: center;
                text-align: center;
                text-transform: uppercase;
                color: ${colors.black};
                margin-bottom: 12px;
            }
            .date{
                font-family: Josefin Sans;
                font-style: normal;
                font-weight: normal;
                font-size: 12px;
                line-height: 12px;
                display: flex;
                align-items: center;
                text-align: center;
                text-transform: uppercase;

                color: ${colors.black};
            }

            .chrono-bar{
                height: 264px;
                width: 100%;
                display: flex;
                align-items: center;
                justify-content: center;
                margin: 6px 0px;
                position: relative;

                .thin-bar{
                    height: 100%;
                    width: 2px;
                    background: rgba(130, 33, 29, 0.5);
                }

                .bold-bar{
                    width: 4px;
                    height: 50px;
                    background: ${colors.red};
                    position: absolute;
                }
            }
        }
        .title{
                font-family: Josefin Sans;
                font-style: normal;
                font-weight: bold;
                font-size: 24px;
                line-height: 24px;
                display: flex;
                align-items: center;
                text-transform: uppercase;

                color: ${colors.primary};
                margin-bottom: 36px;
        }
            
        .para{
            font-family: Josefin Sans;
            font-style: normal;
            font-weight: normal;
            font-size: 16px;
            line-height: 20px;

            color: ${colors.primary};
        }

        .div-pres{
            width: 25%;
        }

        .div-ambition{
            width: 25%;
            margin-right: 144px;
        }
    }

    @media screen and (max-width: 840px){
        .vector-position{
            display: none;
        }
        .container-content{
            flex-direction: column;
            justify-content: space-between;
            align-items: center;
            padding: 48px;

            .road-corp{
            order: 3;
            display: flex;
            width: 70%;

            .chrono-bar{
                transform: rotate(90deg);
            }
            .chrono-info{
                width: 80px;
            }

            }
            .div-pres{
                display: flex;
                flex-direction: column;
                align-items: center;

                width: 70%;
                margin-bottom: 36px;

                p{
                    text-align: center;
                }
            }
            .div-ambition{
                display: flex;
                flex-direction: column;
                align-items: center;

                width: 70%;
                margin-right: unset!important;

                p{
                    text-align: center;
                }
            }
        }
    }

    @media screen and (max-width: 640px){
        .container-content{

            .road-corp{
                width: 100%;
            }
            .chrono-info{
                width: 100px!important;
            }
            .div-pres{
                width: 95%!important;
            }
            .div-ambition{
                width: 95%!important;
            }
        }
    }


`

export default StyledPresentation