import styled from 'styled-components'
import { colors } from '../../Theme/Helpers'

const StyledFooter = styled.footer`
    .separator{
        height: calc(46px * 2);
        position: relative;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
            .thin-bar{
                height: 2px;
                width: 250px;
                background: ${colors.primary};
                opacity: 0.50
            }

            .bold-bar{
                width: 50px;
                height: 4px;
                background: ${colors.primary};
                position: absolute;
            }
    }

    .footer-contact{
        height: 361px;
        display: flex;
        background-color: ${colors.primary};
        padding: 24px 96px; 

        .info{
            margin-right: 60px;
            width: 30%;
        }

        .title-call{
            font-family: Josefin Sans;
            font-style: normal;
            font-weight: 600;
            font-size: 30px;
            line-height: 30px;
            text-transform: uppercase;

            color: ${colors.white};
            margin-bottom: 24px;
        }

        .container-email-tel{
            display: flex;
            justify-content: flex-start;
            align-items: center;
            margin-bottom: 24px;

            .email{
                display: flex;
                flex-direction: column;
                margin-right: 24px;
            }

            .tel{
                display: flex;
                flex-direction: column;
            }
        }

        .contact-value{
            font-family: Josefin Sans;
            font-style: normal;
            font-weight: 600;
            font-size: 14px;
            line-height: 20px;
            color: ${colors.red};
        }

        .address{
            display: flex;
            flex-direction: column;
            width: 75%;
            margin-bottom: 48px;
        }
        .text-contact{
            font-family: Josefin Sans;
            font-style: normal;
            font-weight: normal;
            font-size: 14px;
            line-height: 20px;
            color: ${colors.white};
            text-decoration: none;
        }

        .title-form{
            font-family: Josefin Sans;
            font-style: normal;
            font-weight: normal;
            font-size: 24px;
            line-height: 24px;
            display: flex;
            align-items: center;

            color: ${colors.red};
        }

        .social{
            margin-right: 48px;
        }

        .input_select{
            user-select: text;
        }

        .container-form{
            width:70%;
            height: 50%;
            position: relative;

            form{
                display: flex;
                height: 100%;
                justify-content: space-between;
                margin-top: 24px;

                div {
                    width: 30%;
                    display: flex;
                    flex-direction: column;
                    justify-content: space-between;
                    align-content: space-between;

                    
                    .input-formulary{
                        width: 100%;
                        height: 48px;
                        border-bottom: solid 2px #E5E5E5;
                        background-color: ${colors.primary};
                        font-family: Josefin Sans;
                        font-style: normal;
                        font-weight: thin;
                        font-size: 14px;
                        line-height: 14px;
                        display: flex;
                        align-items: center;

                        color: rgba(229, 229, 229, 1);
                        outline: none;
                        opacity: 0.5;
                    }

                    .input-formulary::placeholder{
                        font-family: Josefin Sans;
                        font-style: normal;
                        font-weight: normal;
                        font-size: 14px;
                        line-height: 14px;
                        display: flex;
                        align-items: center;

                        color: rgba(229, 229, 229, 0.3);
                    }

                    textarea{
                        border-bottom: solid 2px #E5E5E5;
                        height: 100%;
                        resize: none;
                        background-color: ${colors.primary};
                        font-family: Josefin Sans;
                        font-style: normal;
                        font-weight: normal;
                        font-size: 14px;
                        line-height: 14px;
                        display: flex;
                        align-items: center;
                        outline: none;

                        color: rgba(229, 229, 229, 1);
                        opacity: 0.5;
                    }

                    textarea::placeholder{
                        font-family: Josefin Sans;
                        font-style: normal;
                        font-weight: normal;
                        font-size: 14px;
                        line-height: 14px;
                        display: flex;
                        align-items: center;
                        color: rgba(229, 229, 229, 0.3);
                    }

                }

                .submit-button{
                    width: 143px;
                    height: 48px;
                    position: absolute;
                    bottom: -160px;
                    left: calc(50% - 143px);
                    right: calc(50% - 143px);
                    background-color: ${colors.red};
                    color: ${colors.white};
                    border-radius: 4px;
                    cursor: pointer;
                    transition: 400ms;
                    outline: none;
                }

                .submit-button:hover{
                    background: #AE2823;
                }
            }
        }
        }

        @media screen and (max-width: 1270px){
            .footer-contact{
                height: auto;
                padding: 24px 60px 15px 60px;

                .container-email-tel{
                    flex-direction: column;
                    align-items: flex-start;

                    .email{
                        margin-bottom: 12px;
                    }
                }
            }

            .address{
                margin-bottom: 12px!important;
            }
        }

        @media screen and (max-width: 880px){
            .footer-contact{
                flex-direction: column;
                align-items: center;
                padding: 24px 60px 130px 60px!important;

                .info{
                    align-items: center;
                    margin-right: unset;
                    width: 50%;

                    .title-call{
                        text-align: center;
                    }

                    .container-email-tel{
                        flex-direction: column;
                        align-items: center;

                        .email{
                            margin-bottom: 12px;
                            margin-right: unset;
                            text-align: center;
                        }

                        .tel{
                            text-align: center;
                        }

                    }
                    .address{
                        width: 100%;
                        text-align: center;
                        margin-bottom: 12px!important;

                        p{
                            width: 100%;
                        }
                    }

                    .social-part{
                        text-align: center;
                        margin: 24px 0px;
                    }
                }
            }
            .container-form{
                width: 90%!important;
                .title-form{
                    justify-content: center;
                }

                form {
                    flex-direction: column;
                    align-items: center;

                    div{
                        width: 50%!important;
                    }
                }
            }

            .submit-button{
                    width: 20%!important;
                    bottom: -84px!important;
                    left: 40%!important;
                    right: 40%!important;
                    background-color: ${colors.red};
                    color: ${colors.white};
                    border-radius: 4px;
                    cursor: pointer;
                    transition: 400ms;
                    outline: none;
            }

            .input-formulary{
                margin-bottom: 24px;
            }

            textarea{
                height:100px!important;
            }
        }

        @media screen and (max-width: 680px){
            .container-form{
                width: 100%!important;
                .title-form{
                    justify-content: center;
                }

                form {
                    flex-direction: column;
                    align-items: center;

                    div{
                        width: 95%!important;
                    }
                }
            }

            .submit-button{
                    width: 30%!important;
                    bottom: -84px!important;
                    left: 35%!important;
                    right: 35%!important;
                    background-color: ${colors.red};
                    color: ${colors.white};
                    border-radius: 4px;
                    cursor: pointer;
                    transition: 400ms;
                    outline: none;
            }

            .input-formulary{
                margin-bottom: 36px;
            }

            textarea{
                margin-top: 12px;
            }
        }

`

export default StyledFooter