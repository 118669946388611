import React, { useRef, useState, useEffect } from 'react'
import { Footer } from '../Component/Footer/Footer'
import { Header } from '../Component/Header/Header'
import { Home } from '../Component/Home/Home'
import { Presentation } from '../Component/Presentation/Presentation'
import { TrustUs } from '../Component/TrustUs/TrustUs'
import { WhoWeAre } from '../Component/WhoWeAre/WhoWeAre'

export const Landing = () => {
    let footer = useRef()
    let client = useRef()
    let qui = useRef()
    let presentation = useRef()

    const [coordonate, setCoordonate] = useState ({
        x: 0,
        y: 0
    })

    const [scrollValue, setScrollValue] = useState(0)

    const getCoordonate = (e) => {
        setCoordonate({
            x: e.clientX * 0.02,
            y: e.clientY * 0.02
        })
    }

    const getOffsetTop = (e) => {
        setScrollValue(e.target.scrollingElement.scrollTop)
    }

    useEffect(() => {
        window.addEventListener('scroll', getOffsetTop, { passive: true })
    }, [])

    useEffect(() => {
        return () => window.removeEventListener('scroll', getOffsetTop)
    }, [])

    return (
        <div onMouseMove={getCoordonate}>
            <Header isTop={scrollValue === 0 ? true : false}
                    footer={footer}
                    client={client}
                    qui={qui}
                    presentation={presentation}
            />
            <Home coordonate={coordonate}/>
            <Presentation presentation={presentation}/>
            <WhoWeAre qui={qui}/>
            <TrustUs client={client}/>
            <Footer footer={footer}/>
        </div>
    )
}
