import styled from 'styled-components'

const StyledCrossMenu = styled.div`

    @keyframes animIcon {
        from {
            opacity: 0;
        }

        to {
            opacity: 1;
        }   
    }   

    svg {
        animation: animIcon cubic-bezier(0.71, -0.01, 0.4, 0.97) 500ms;
    }
    `

    export default StyledCrossMenu