import styled from 'styled-components'
import { colors } from '../../Theme/Helpers'

const StyledTrustUs = styled.div `
    background-color: ${colors.red};
    
    h1{
        font-family: Josefin Sans;
        font-style: normal;
        font-weight: bold;
        font-size: 30px;
        line-height: 30px;
        text-align: center;
        text-transform: uppercase;
        padding: 24px 0px;

        color: #EBE2E2;
    }

    div{
        display: flex;
        align-items: center;
        justify-content: center;
        img{
        width: 10%;
        margin: 24px calc(70% / 6);
        opacity: 1;
        }
    }

    @media screen and (max-width: 900px){
        padding-bottom: 36px;

        h1 {
            padding: 36px 0px 60px;
        }

        div{
            flex-direction: column;

            img{
                width: 30%;
                margin-bottom: 48px!important;
                margin: unset;
            }
        }
    }
`

export default StyledTrustUs