import React from 'react'
import StyledWhoWeAre from './StyledWhoWeAre'
import Cofounder from '../../Images/Co-founder.jpg'
import Founder from '../../Images/Founder.jpg'


export const WhoWeAre = ({qui}) => {
    return (
        <StyledWhoWeAre ref={qui}>
            <h1 className='title-who'>
                qui sommes nous
            </h1>
            <div className='pres'>
                <div className='founder-corp'>
                    <div className='denomination'>
                        <h3>christophe neret</h3>
                        <span>co-fondateur</span>
                    </div>
                    <div className='description'>
                        <p>
                            Expert dans la mise en place de systèmes d’information ERP et EPM, 
                            Christophe à une solide expérience multi disciplinaire, à la fois management/delivery 
                            de projet IT, contrôle de gestion, pilotage de la transformation/performance et activité commerciale.
                        </p>
                        <div>
                            <button className='hover-button'>
                                LINKEDIN
                            </button>
                        </div>
                    </div>
                    <img src={Cofounder} alt="co-founder of xlance corp"/>
                </div>
                <div className='founder-corp'>
                    <div className='denomination'>
                        <h3>eric agniel</h3>
                        <span>co-fondateur</span>
                    </div>
                    <div className='description'>
                        <p>
                            Fort de plus de 20 ans d'expérience en management de ressources au sein de sociétés de conseil et services informatiques, 
                            et plus particulièrement en assistance à maitrise d'ouvrage et accompagnement vers la transformation numérique, Eric dispose 
                            d'un réseau de partenaires et experts techniques ou fonctionnels, tant dans le domaine des ERP, du décisionnel et de la Data, 
                            que dans celui de la conception et le développement de solutions personnalisées.
                        </p>
                        <div>
                            <button className='hover-button'>
                                LINKEDIN
                            </button>
                        </div>
                    </div>
                    <img src={Founder} alt="founder of xlance corp"/>
                </div>
            </div>
        </StyledWhoWeAre>
    )
}
