import React from 'react'
import StyledBurgerMenu from './StyledBurgerMenu'

export const BurgerMenu = () => {
    return (
        <StyledBurgerMenu>
            <svg width="40" height="22" viewBox="0 0 40 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M0 9H40L35 13H0V9Z" fill="#82211D"/>
                <path d="M0 0H40L35 4H0V0Z" fill="#82211D"/>
                <path d="M0 18H40L35 22H0V18Z" fill="#82211D"/>
            </svg>
        </StyledBurgerMenu>
    )
}
