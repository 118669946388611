import React, {useState} from 'react'
import StyledHeader from './StyledHeader'

import Logo from '../../Images/LogoXlance.svg'
import UnderlineMenu from '../../Images/UnderlineMenu.svg'

import { BurgerMenu } from '../BurgerMenu/BurgerMenu'
import { CrossMenu } from '../CrossMenu/CrossMenu'


export const Header = ({footer, isTop, client, qui, presentation}) => {

    const [navIsOpen, setNavIsOpen] = useState(null)

    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        })
    }

    const scrollToRef = (ref) => {
        let top = ref.current.offsetTop - 48
        window.scrollTo({
            top,
            behavior: 'smooth'
        })
    }

    const toggleNav = () => {
        if(navIsOpen === null){
            setNavIsOpen(true)
        }else{
            setNavIsOpen(!navIsOpen)
        }
    }

    return (
        <StyledHeader isTop={isTop}
                    navIsOpen={navIsOpen}
        >
            <div onClick={scrollToTop} className='div-logo'>
                <img src={Logo} alt="Logo XLANCE"/>
            </div>
            <nav className='div-menu'>
                <ul>
                    <li onClick={() => scrollToRef(presentation)}>
                        <span>PRÉSENTATION</span>
                        <img className='pick' src={UnderlineMenu} alt="figure"/>
                    </li>
                    <li onClick={() => scrollToRef(qui)}>
                        <span>QUI SOMMES NOUS</span>
                        <img className='size-uderline pick' src={UnderlineMenu} alt="figure"/>
                    </li>
                    <li onClick={() => scrollToRef(client)}>
                        <span>NOS CLIENTS</span>
                        <img className='size-uderline-client pick' src={UnderlineMenu} alt="figure"/>
                    </li>
                    <li onClick={() => scrollToRef(footer)}>
                        <span>CONTACT</span>
                        <img className='size-uderline-contact pick' src={UnderlineMenu} alt="figure"/>
                    </li>
                    <li>
                        <img className='image-logo' src={Logo} alt="Logo XLANCE"/>
                    </li>
                </ul>
            </nav>
            <div onClick={toggleNav} className='burger-menu'>
                {
                    navIsOpen
                    ? <CrossMenu/>
                    : <BurgerMenu/>
                }
            </div>
        </StyledHeader>
    )
}
