import styled from 'styled-components'

import { colors } from '../../Theme/Helpers'

const StyledHome = styled.section`
position: relative;
width: 100%;
height: auto;
overflow: hidden;

    @keyframes scrollAnim {
        0% {
            transform: translateY(-10px);
        }
        50% {
            transform: translateY(5px);
        }
        100%{
            transform: translateY(-10px);
        }
    }

    .background{
        width: 110%;
        height: auto;
        margin: -50px -50px
    }

    .title-home{
        position: absolute;
        top: 0px;
        left: 0px;
        width: 100%;
        height: 948px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        z-index: 1;

        .position-denomination{
            display: flex;
            span{
                font-family: Josefin Sans;
                font-style: normal;
                font-weight: 600;
                font-size: 24px;
                line-height: 24px;
                display: flex;
                align-items: center;
                text-align: center;
                letter-spacing: 0.2em;
                color: ${colors.white};
                text-transform: uppercase;
                margin: 12px;
            }
        }

        img{
            width: 50%;
        }


    }

    .vector-decoration{
        display: flex;
        position: absolute;
        bottom: 0px;
        left: 125px;
        height: 16px;
        z-index: 1;
    }

    .scroll{
        position: absolute;
        bottom: 60px;
        left : 50%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;

        p{
            font-family: Josefin Sans;
            font-style: normal;
            font-weight: 300;
            font-size: 12px;
            line-height: 12px;
            display: flex;
            align-items: center;
            text-align: center;
            margin-bottom: 24px;

            color: #E5E5E5;
        }

        img{
            z-index: 1;
            cursor: pointer;
            animation: scrollAnim cubic-bezier(0.71, -0.01, 0.4, 0.97) 3s infinite;
        }
    }

    @media screen and (max-width: 1300px){
        .title-home{
        top: calc(50% - 146px);
        width: 100%;
        height: unset!important;

            img{
                width: 40%;
            }

            .position-denomination{
                span{
                    font-weight: 600;
                    font-size: 16px;
                }
            }
        }

        .scroll{
        left : 45%;
        }
    }

    @media screen and (max-width: 980px){
        .scroll{
            display: none;
        }

        .vector-decoration{
            display: none;
        }

        .title-home{
            top: calc(40%);

            .position-denomination{
                display: flex;
                flex-direction: column;
                align-items: center;

                .separator{
                    display: none;
                }

                span{
                    font-weight: 400;
                    font-size: 14px;
                    line-height: 14px;
                }
            } 
        }
    }

    @media screen and (max-width: 800px){
        .background{
            height: 700px;
            width:auto;
        }
    }
`

export default StyledHome